import baseApi from './base_api.js'

//  注册
export function addUser(options) {
  return baseApi.post('/addUser', {...options})
}

//  用户名校验
export function userCheck(options) {
  return baseApi.post('/userCheck', {...options})
}

//  登录
export function loginUser(options) {
  return baseApi.post('/loginUser', {...options})
}