<template>
  <div class="hello">
    <div class="big_box">
      <div class="title_box">
        <div class="slider" :style="sliderStyle"></div>
        <div class="title_content" @click="type = 0" :style="{color: type === 0 ? '#f3f3f3' : '#000'}">登 录</div>
        <div class="title_content" @click="type = 1" :style="{color: type === 1 ? '#f3f3f3' : '#000'}">注 册</div>
      </div>
      <div class="content_box">
        <div class="common name">
          <i class="iconfont iconzhanghao"></i>
          <input class="inputArea" :style="{width: type == 1 ? '2rem' : '3rem'}" type="text" maxlength="20" v-model="userName" placeholder="请输入昵称">
          <span class="page_tip" v-show="type == 1"><i class="iconfont" :class="[nameOk ?  'iconchenggong' : 'iconjinggao']" v-show="userName"></i>{{nameMes}}</span>
        </div>
        <div class="tip"> <span v-show="type == 1">(最多20个字符)</span></div>
        <div class="common pwd">
          <i class="iconfont iconmima"></i>
          <input class="inputArea" :style="{width: type == 1 ? '2rem' : '3rem'}" type="password" minlength="4" maxlength="15" v-model="passWord" placeholder="请输入密码">
          <span class="page_tip" v-show="type == 1"><i class="iconfont" :class="[pwdOk ?  'iconchenggong' : 'iconjinggao']" v-show="passWord"></i>{{pwdMes}}</span>
        </div>
        <div class="tip"> <span v-show="type == 1">(4~15位，暂时只支持英文大小写，数字)</span></div>
        <div class="common pwd" v-show="type == 1">
          <i class="iconfont iconmima"></i>
          <input class="inputArea" :style="{width: type == 1 ? '2rem' : '3rem'}" type="password" minlength="4" maxlength="15" v-model="passWordAgain" placeholder="请再次输入密码">
          <span class="page_tip" v-show="type == 1 && passWordAgain"><i class="iconfont" :class="[pwdAgainOk ?  'iconchenggong' : 'iconjinggao']" v-show="passWord"></i>{{pwdAgainMes}}</span>
        </div>
      </div>
      <div class="button_box">
        <div class="button register" :style="{background: !pwdAgainOk || !pwdOk || !nameOk ? 'gray' : '#336699'}" @click="register" v-show="type === 1">注 册</div>
        <div class="button submit" :style="{background: !userName || !passWord ? 'gray' : '#336699'}" @click="login" v-show="type === 0">登 录</div>
      </div>
      <pop ref="submitBox" :title="finalMes" cancel-text="取消" :show-sure=false @cancel="submitCancel" />
      <pop ref="registerBox" :title="finalMes" sure-text="登录" cancel-text="取消" :show-sure="registerOk" @sure="registerSure" @cancel="registerCancel" />
    </div>
  </div>
</template>

<script>
import { addUser, userCheck, loginUser } from '../../vue_api/user.js'
import pop from '@/component/pop/pop.vue'
import _ from 'lodash'
export default {
  name: 'addUser',
  components: {
    pop
  },
  data () {
    return {
      type: 0,
      sliderStyle: {
        left: 0
      },
      userName: '',
      passWord: '',
      passWordAgain: '',
      nameMes: '陌上☿人如玉',
      pwdMes: '公子⚨世无双',
      pwdAgainMes: '',
      finalMes: '',
      nameOk: 0,
      pwdOk: 0,
      pwdAgainOk: 0,
      registerOk: false,
      okey: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
      hhakey: '5S82eDDrr1UV348w/dT@cBdu0dDFrj=W6D!!u7KJMA@SWQOaR2JvenDNdh=iux-X9ZFnIysxEtFpoT5jH18TiunW8C2dcnZfV32kenc9/L6eLMsKwOg8pewiYRYa',
      hhakeyArr: ['5S', '82', 'eD', 'Dr', 'r1', 'UV', '34', '8w', '/d', 'T@', 'cB', 'du', '0d', 'DF', 'rj', '=W', '6D', '!!', 'u7', 'KJ', 'MA', '@S', 'WQ', 'Oa', 'R2', 'Jv', 'en', 'DN', 'dh', '=i', 'ux', '-X', '9Z', 'Fn', 'Iy', 'sx', 'Et', 'Fp', 'oT', '5j', 'H1', '8T', 'iu', 'nW', '8C', '2d', 'cn', 'Zf', 'V3', '2k', 'en', 'c9', '/L', '6e', 'LM', 'sK', 'wO', 'g8', 'pe', 'wi', 'YR', 'Ya']
    }
  },
  watch: {
    type (val) {
      this.sliderStyle = val === 0 ? { left: '0' } : { left: '1rem' }
      if (!this.registerOk) {
        this.userName = ''
        this.passWord = ''
        this.passWordAgain = ''
        this.pwdAgainMes = ''
        this.registerOk = false
      }
    },
    userName (val) {
      // console.log('userName改变---', val, this.lodash.debounce)
      if (this.type == 1) {
        if (val) {
          this.checkName(val)
        } else {
          this.nameMes = '陌上☿人如玉'
          this.nameOk = 0
        }
      }
    },
    passWord (val) {
      if (this.type == 1) {
        if (val) {
          this.checkPwd(val)
        } else {
          this.pwdMes = '公子⚨世无双'
        }
        this.pwdAgainOk = val === this.passWordAgain ? 1 : 0
        this.pwdAgainMes = val === this.passWordAgain ? '两次输入相同' : '两次输入不一致'
      }
    },
    passWordAgain (val) {
      this.pwdAgainOk = val === this.passWord ? 1 : 0
      this.pwdAgainMes = val === this.passWord ? '两次输入相同' : '两次输入不一致'
    }
  },
  computed: {
    passWordKey () {
      const passWordKeyArr = []
      this.passWord.split('').forEach((item) => {
        const keyIndex = this.okey.split('').findIndex((oitem) => { return item === oitem })
        // console.log('index----', keyIndex)
        passWordKeyArr.push(this.hhakey.slice(keyIndex * 2, keyIndex * 2 + 2))
      })
      return passWordKeyArr.join('')
    }
  },
  mounted () {
    const chatUserShow = localStorage.getItem('chatUserShow') ? JSON.parse(localStorage.getItem('chatUserShow')) : ''
    console.log('localstroage-----', chatUserShow)
    this.userName = chatUserShow.userName || ''
    const passWordKey = chatUserShow.passWordKey || ''
    let pArr = []
    console.log('localstroage-----passWordKey----------', passWordKey)
    for (let i = 0; i < passWordKey.length / 2; i++) {
      console.log('iiiiiiii-------', i, passWordKey.substring(i * 2, i * 2 + 2))
      const index = this.hhakeyArr.findIndex((item) => { return item === passWordKey.substring(i * 2, i * 2 + 2) })
      if (index !== -1) {
        pArr.push(this.okey.split('')[index])
      }
    }
    if (pArr.length > 0) {
      console.log('pArr----------', pArr.join(''))
      this.passWord = pArr.join('')
    }
    if (this.userName && this.passWord && !this.$route.query.noAuto) {
      this.login()
    }
  },
  methods: {
    submitSure () {
      this.$refs.submitBox.close()
    },
    submitCancel () {
      this.$refs.submitBox.close()
    },
    registerSure () {
      this.type = 0
      this.$refs.registerBox.close()
    },
    registerCancel () {
      this.$refs.registerBox.close()
    },
    // 重名校验
    checkName: _.debounce(function (val) {
      // 这里不能用checkName: _.debounce((val)=> {  因为下面会获取不到this
      // console.log('this是指向哪', this)
      userCheck({ userName: val }).then((res) => {
        console.log('checkName完毕------', res)
        this.nameOk = res.data.status ? 1 : 0
        this.nameMes = res.data.message
      })
    }, 400),
    // 密码规范检验
    checkPwd: function (val) {
      const patt = new RegExp('^[0-9a-zA-Z]*$')
      if (val && val.length > 3 && val.length < 16 && patt.test(val)) {
        this.pwdMes = '该密码可用'
        this.pwdOk = 1
      } else {
        this.pwdMes = '该密码无效'
        this.pwdOk = 0
      }
    },
    // 注册
    async register () {
      if (!this.pwdAgainOk || !this.pwdOk || !this.nameOk) return
      console.log('你点击了按钮准备请求服务端------')
      const params = {
        userName: this.userName,
        passWord: this.passWordKey
      }
      await addUser(params).then((res) => {
        console.log('请求成功------', res.data.message)
        this.registerOk = res.data.status
        this.finalMes = res.data.message
        this.$refs.registerBox.open()
      })
    },
    // 登录
    async login () {
      if (!this.userName || !this.passWord) return
      console.log('你点击了按钮准备请求服务端------', this.$refs.submitBox)
      const params = {
        userName: this.userName,
        passWord: this.passWordKey
      }
      await loginUser(params).then((res) => {
        console.log('登录请求成功------', res.data)
        this.finalMes = res.data.message
        if (res.data.status) {
          localStorage.setItem('chatUser', JSON.stringify({
            userId: res.data.userInfo.userId || null,
            userName: this.userName
          }))
          localStorage.setItem('chatUserShow', JSON.stringify({
            userId: res.data.userInfo.userId || null,
            userName: this.userName,
            passWordKey: this.passWordKey
          }))
          this.$bus.$emit('getUserName', this.userName)
          this.$bus.$emit('getUserId', res.data.userInfo.userId || null)
          this.$router.push({
            path: '/'
          })
        } else {
          this.$refs.submitBox.open()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
